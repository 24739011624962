
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/model';

@Options({})
export default class Event extends Vue {

    @Prop({
        default: () => new OM.EventVm()
    }) event: OM.EventVm;

}

