import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/img/management/search.svg'
import _imports_1 from '@/assets/img/management/edit.svg'


const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "mt-5" }
const _hoisted_3 = { class: "form-group squareInputContainer withLabel w-100 me-2" }
const _hoisted_4 = { class: "form-group squareInputContainer withLabel" }
const _hoisted_5 = { class: "d-flex align-items-center flex-column" }
const _hoisted_6 = { class: "form-group squareInputContainer withLabel w-100 me-2" }
const _hoisted_7 = { class: "form-group squareInputContainer withLabel" }
const _hoisted_8 = { class: "d-flex align-items-center  flex-column" }
const _hoisted_9 = { class: "form-group squareInputContainer withLabel w-100 me-2" }
const _hoisted_10 = { class: "form-group squareInputContainer withLabel" }
const _hoisted_11 = { class: "d-flex align-items-center" }
const _hoisted_12 = { class: "form-group squareInputContainer withLabel w-100 me-1" }
const _hoisted_13 = { class: "form-group squareInputContainer withLabel w-100 ms-1" }
const _hoisted_14 = { class: "d-flex" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 0,
  src: _imports_0,
  class: "me-2"
}
const _hoisted_17 = {
  key: 2,
  src: _imports_1,
  class: "me-2"
}
const _hoisted_18 = { key: 3 }
const _hoisted_19 = { class: "d-flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_profile_image = _resolveComponent("user-profile-image")!
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_file_selector = _resolveComponent("file-selector")!
  const _directive_date = _resolveDirective("date")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_file_selector, {
      modelValue: _ctx.$store.state.createEventVm.logo,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.state.createEventVm.logo = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_user_profile_image, {
          class: "profileImageRound",
          src: _ctx.logoPreview
        }, null, 8, ["src"]),
        _createVNode(_component_localized_text, {
          localizedKey: "app_cambiafotologo",
          text: "Cambia foto logo",
          class: "fs_14 grey d-block text-center mt-2"
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_file_selector, {
        modelValue: _ctx.$store.state.createEventVm.backgroundImage,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.state.createEventVm.backgroundImage = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_user_profile_image, {
            class: "profileImageRound",
            src: _ctx.backgroundPreview
          }, null, 8, ["src"]),
          _createVNode(_component_localized_text, {
            localizedKey: "app_cambiafotocopertina",
            text: "Cambia foto copertina",
            class: "fs_14 grey d-block text-center mt-2"
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_nomeevento",
            text: "Nome Evento",
            class: "fs_12 grey"
          })
        ]),
        _withDirectives(_createVNode("input", {
          type: "text",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$store.state.createEventVm.title = $event)),
          class: "genericInput squareInput"
        }, null, 512), [
          [_vModelText, _ctx.$store.state.createEventVm.title],
          [_directive_date]
        ])
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode("label", null, [
          _createVNode(_component_localized_text, {
            localizedKey: "app_scriviunadescrizione",
            text: "Scrivi una descrizione",
            class: "fs_12 grey"
          })
        ]),
        _withDirectives(_createVNode("textarea", {
          class: "genericInput squareInput ptInput",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$store.state.createEventVm.description = $event))
        }, null, 512), [
          [_vModelText, _ctx.$store.state.createEventVm.description]
        ])
      ]),
      _createVNode(_component_localized_text, {
        class: "fs_12 greyBlue d-block mt-2 mb-2",
        localizedKey: "app_inizioevento",
        text: "Inizio evento"
      }),
      _createVNode("div", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "app_data",
              text: "Data",
              class: "fs_12 grey"
            })
          ]),
          _withDirectives(_createVNode("input", {
            type: "date",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$store.state.createEventVm.dateFrom = $event)),
            class: "genericInput squareInput dateInput infoDate"
          }, null, 512), [
            [_vModelText, _ctx.$store.state.createEventVm.dateFrom],
            [_directive_date]
          ])
        ]),
        _createVNode("div", _hoisted_7, [
          _createVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "app_ora",
              text: "Ora",
              class: "fs_12 grey"
            })
          ]),
          _withDirectives(_createVNode("input", {
            type: "time",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$store.state.createEventVm.timeFrom = $event)),
            class: "genericInput squareInput dateInput infoDate"
          }, null, 512), [
            [_vModelText, _ctx.$store.state.createEventVm.timeFrom]
          ])
        ])
      ]),
      _createVNode(_component_localized_text, {
        class: "fs_12 greyBlue d-block mt-2 mb-2",
        localizedKey: "app_fineevento",
        text: "Fine evento"
      }),
      _createVNode("div", _hoisted_8, [
        _createVNode("div", _hoisted_9, [
          _createVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "app_data",
              text: "Data",
              class: "fs_12 grey"
            })
          ]),
          _withDirectives(_createVNode("input", {
            type: "date",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.$store.state.createEventVm.dateTo = $event)),
            class: "genericInput squareInput dateInput infoDate"
          }, null, 512), [
            [_vModelText, _ctx.$store.state.createEventVm.dateTo],
            [_directive_date]
          ])
        ]),
        _createVNode("div", _hoisted_10, [
          _createVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "app_ora",
              text: "Ora",
              class: "fs_12 grey"
            })
          ]),
          _withDirectives(_createVNode("input", {
            type: "time",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.$store.state.createEventVm.timeTo = $event)),
            class: "genericInput squareInput dateInput infoDate"
          }, null, 512), [
            [_vModelText, _ctx.$store.state.createEventVm.timeTo]
          ])
        ])
      ]),
      _createVNode(_component_localized_text, {
        class: "fs_12 greyBlue d-block mt-2 mb-2",
        localizedKey: "app_oraridiaperturatestuale",
        text: "Orari di apertura (testo)"
      }),
      _createVNode("div", _hoisted_11, [
        _createVNode("div", _hoisted_12, [
          _createVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "app_dalle",
              text: "Dalle",
              class: "fs_12 grey"
            })
          ]),
          _withDirectives(_createVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.$store.state.createEventVm.openFrom = $event)),
            class: "genericInput squareInput"
          }, null, 512), [
            [_vModelText, _ctx.$store.state.createEventVm.openFrom],
            [_directive_date]
          ])
        ]),
        _createVNode("div", _hoisted_13, [
          _createVNode("label", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "app_alle",
              text: "Alle",
              class: "fs_12 grey"
            })
          ]),
          _withDirectives(_createVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.$store.state.createEventVm.openTo = $event)),
            class: "genericInput squareInput"
          }, null, 512), [
            [_vModelText, _ctx.$store.state.createEventVm.openTo]
          ])
        ])
      ]),
      _createVNode(_component_localized_text, {
        class: "fs_12 greyBlue d-block mt-2 mb-2",
        localizedKey: "app_eventoOnline",
        text: "Evento online?"
      }),
      _createVNode("div", _hoisted_14, [
        _createVNode("div", {
          class: ["filterTab w-100 me-1", !_ctx.$store.state.createEventVm.isOnlineEvent ? 'active' : ''],
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$store.state.createEventVm.isOnlineEvent = false))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_12 d-block",
            localizedKey: "app_no",
            text: "No"
          })
        ], 2),
        _createVNode("div", {
          class: ["filterTab w-100 ms-1", _ctx.$store.state.createEventVm.isOnlineEvent ? 'active' : ''],
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$store.state.createEventVm.isOnlineEvent = true))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_12 d-block",
            localizedKey: "app_si",
            text: "Si"
          })
        ], 2)
      ]),
      (!_ctx.$store.state.createEventVm.isOnlineEvent)
        ? (_openBlock(), _createBlock("div", _hoisted_15, [
            _createVNode(_component_localized_text, {
              class: "fs_12 greyBlue d-block mt-2 mb-2",
              localizedKey: "app_indirizzo",
              text: "Indirizzo"
            }),
            _createVNode("div", {
              class: "mb-3 d-flex align-items-center",
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.searchCity()))
            }, [
              (!_ctx.$store.state.createEventVm.address.typedAddress)
                ? (_openBlock(), _createBlock("img", _hoisted_16))
                : _createCommentVNode("", true),
              (!_ctx.$store.state.createEventVm.address.typedAddress)
                ? (_openBlock(), _createBlock(_component_localized_text, {
                    key: 1,
                    localizedKey: 'app_selezionaunindirizzo',
                    text: 'Seleziona un\'indirizzo'
                  }))
                : _createCommentVNode("", true),
              (_ctx.$store.state.createEventVm.address.typedAddress)
                ? (_openBlock(), _createBlock("img", _hoisted_17))
                : _createCommentVNode("", true),
              (_ctx.$store.state.createEventVm.address.typedAddress)
                ? (_openBlock(), _createBlock("span", _hoisted_18, _toDisplayString(_ctx.$store.state.createEventVm.address.typedAddress), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_localized_text, {
        class: "fs_12 greyBlue d-block mt-2 mb-2",
        localizedKey: "app_visibilitaevento",
        text: "Visibilità evento"
      }),
      _createVNode("div", _hoisted_19, [
        _createVNode("div", {
          class: ["filterTab", _ctx.$store.state.createEventVm.communityType == _ctx.communityPublic ? 'active' : ''],
          onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$store.state.createEventVm.communityType = _ctx.communityPublic))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_12 d-block",
            localizedKey: "app_pubblica",
            text: "Pubblica"
          })
        ], 2),
        _createVNode("div", {
          class: ["filterTab", _ctx.$store.state.createEventVm.communityType == _ctx.communityOnRequest ? 'active' : ''],
          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.$store.state.createEventVm.communityType = _ctx.communityOnRequest))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_12 d-block",
            localizedKey: "app_surichiesta",
            text: "Su richiesta"
          })
        ], 2),
        _createVNode("div", {
          class: ["filterTab", _ctx.$store.state.createEventVm.communityType == _ctx.communityClose ? 'active' : ''],
          onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.$store.state.createEventVm.communityType = _ctx.communityClose))
        }, [
          _createVNode(_component_localized_text, {
            class: "fs_12 d-block",
            localizedKey: "app_chiuso",
            text: "Chiuso"
          })
        ], 2)
      ])
    ])
  ]))
}