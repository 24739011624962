
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import SelectCityModal from '@/components/selectCityModal.vue';
import store from '@/store';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({})
export default class Step1 extends Vue {

    logoPreview: any = null;
    communityPublic = OM.CommunityType.Public;
    communityOnRequest = OM.CommunityType.OnRequest;
    communityClose = OM.CommunityType.Close;

    @Watch('$store.state.createEventVm.logo')
    onLogoChange(next, prev){
        this.makeLogoPreview();
    }
    makeLogoPreview(){
        if(store.state.createEventVm.logo){
            if(typeof store.state.createEventVm.logo == "string"){
                this.logoPreview = store.state.createEventVm.logo;
            } else {
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.logoPreview = e.target.result;
                }
                reader.readAsDataURL(store.state.createEventVm.logo);
            }
        }
    }
    
    backgroundPreview: any = null;
    @Watch('$store.state.createEventVm.backgroundImage')
    onBackgroundChange(next, prev){
        this.makeBgPreview();
    }
    makeBgPreview(){
        if(store.state.createEventVm.backgroundImage){
            if(typeof store.state.createEventVm.backgroundImage == "string"){
                this.backgroundPreview = store.state.createEventVm.backgroundImage;
            } else {
                var reader = new FileReader();
                reader.onload = (e: any) => {
                    this.backgroundPreview = e.target.result;
                }
                reader.readAsDataURL(store.state.createEventVm.backgroundImage);
            }
        }
    }

    created(){
        this.makeLogoPreview();
        this.makeBgPreview();
    }

    searchCity() {
        var modalTitle = LocalizationServices.getLocalizedValue("app_indirizzoevento", "Indirizzo evento");
        var question = LocalizationServices.getLocalizedValue("app_dovesiterralevento", "Dove si terrà l\'evento?");

        this.$opModal.show(SelectCityModal, {
            addressTitle: modalTitle,
            addressQuestion: question,
            searchText: store.state.createEventVm.address.completeAddress,
            callback: (city: OM.Address) => {
                store.state.createEventVm.address = city;
                this.$opModal.closeLast();
            }
        })
    }

    getTranslatedText(key: string, text: string){
        return LocalizationServices.getLocalizedValue(key, text)
    }

}

