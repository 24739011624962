
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { getUniversalDate, getUniversalISOStringDate } from '@/utils';
import Step1 from '../components/step1.vue';
import Step2 from '../components/step2.vue';
import Step3 from '../components/step3.vue';
import store from '@/store';
import * as VM from '@/viewModel';
import { BaseCommunityClient, EventClient } from '@/services/Services';

@Options({})
export default class CreateEvent extends Vue {

    @Prop({
        default: ""
    }) communityIdentifier: string;

    created(){
        store.state.createEventVm = new VM.CreateEventVm();
        store.state.createEventVm.communityIdentifier = this.communityIdentifier;
    }

    currentStep: number = 1;
    get step() {
        if(this.currentStep == 1) {
            return Step1;
        } else if(this.currentStep == 2) {
            return Step2;
        } else if(this.currentStep == 3) {
            return Step3;
        }
    }

    get canNext() {
        if(this.currentStep == 1) {
            return store.state.createEventVm.title && store.state.createEventVm.description && store.state.createEventVm.logo && store.state.createEventVm.backgroundImage;
        } else if(this.currentStep == 2) {
            return store.state.createEventVm.category && store.state.createEventVm.tags.length > 0;
        } else if(this.currentStep == 3) {
            return true;
        }
        // else if(this.$route.name == 'community_create_step4') {
        //     return store.state.createEventVm.title && store.state.createEventVm.description && store.state.createEventVm.logo;
        // }
    }

    back() {
        if(this.currentStep > 1) {
            this.currentStep--;
        } else {
            this.$emit('close');
        }
    }

    next() {
        if(this.currentStep == 1) {
            this.currentStep = 2;
        } else if(this.currentStep == 2) {
            this.currentStep = 3
        } else if(this.currentStep == 3) {
            store.state.globalSpinner++;

            var dateFrom = getUniversalDate(store.state.createEventVm.dateFrom); 
            var timeFrom = store.state.createEventVm.timeFrom; 
            var tempDateFrom = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), parseInt(timeFrom.substr(0,2)), parseInt(timeFrom.substr(3,2)))
            store.state.createEventVm.dateFrom = getUniversalISOStringDate(tempDateFrom);
            
            var dateTo = getUniversalDate(store.state.createEventVm.dateTo); 
            var timeTo = store.state.createEventVm.timeTo; 
            var tempDateTo = new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), parseInt(timeTo.substr(0,2)), parseInt(timeTo.substr(3,2)))
            store.state.createEventVm.dateTo = getUniversalISOStringDate(tempDateTo);

            EventClient.createEvent(store.state.createEventVm)
            .then(x => {
                Promise.all([
                    BaseCommunityClient.updateBackgroundImage(x.identifier, store.state.createEventVm.backgroundImage),
                    BaseCommunityClient.updateLogoImage(x.identifier, store.state.createEventVm.logo),
                    BaseCommunityClient.addGalleryImages(x.identifier, store.state.createEventVm.gallery)
                ]).then( y => {
                    this.$router.push('/events/' + x.slug);
                    this.$emit('close');
                }).finally( () => {
                    store.state.globalSpinner--;
                })
            })
            .catch( () => {
                store.state.globalSpinner--;
            });
        }
    }

}

