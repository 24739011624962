import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("input", {
    placeholder: _ctx.placeholderText,
    type: "text",
    class: _ctx.inputClass ? _ctx.inputClass : '',
    value: _ctx.localInput,
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.localInput = $event.target.value)),
    id: "google_autocomplete_province"
  }, null, 42, ["placeholder", "value"]))
}