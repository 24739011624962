
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import googleAutocompleteGetResults from '@/components/googleAutocompleteGetResults.vue';
import * as OM from '@/model';
import { LocalizationServices } from '@/services/LocalizationServices';

@Options({
    components: {
        googleAutocompleteGetResults
    }
})
export default class SelectCityModalView extends Vue {

    @Prop({
        default: ""
    }) addressTitle: string;

    @Prop({
        default: ""
    }) addressQuestion: string;

    provinceList: any[] = [];
    placeholderText: string = "";

    @Prop({
        default: ""
    }) searchText: string;

    @Prop({
        type: [Function]
    }) callback: any;

    localSearchText: string = "";
    selectedCity = new OM.Address();
    geocoder: any;

    created() {
        this.placeholderText = LocalizationServices.getLocalizedValue("app_cercaregioneprovinciaocomune", "Cerca regione, provincia o comune");
        this.geocoder = new google.maps.Geocoder();
        this.localSearchText = this.searchText;
    }

    closeModal(){
        this.$opModal.closeLast();
    }

    searchCityLocation(city: any){
        this.selectedCity.name = city.description;

        var request = {
            placeId: city.place_id
        }
        this.geocoder.geocode(request, this.setLatitudeLognitude);
    }

    setLatitudeLognitude(data, text){
        var request = {
            placeId: data[0].place_id,
            fields: ["address_components", "geometry", "formatted_address", "name"]
        };

        var div = document.createElement("div");

        var service = new google.maps.places.PlacesService(div);
        service.getDetails(request, this.result);

        // this.selectedCity.latitude = data[0].geometry.location.lat();
        // this.selectedCity.longitude = data[0].geometry.location.lng();

        // this.callback(this.selectedCity);
    }

    result(place, status){
        if (status == google.maps.places.PlacesServiceStatus.OK) {
            var address: any = {};
            address.typedAddress = place.formatted_address;
            address.latitude = place.geometry.location.lat();
            address.longitude = place.geometry.location.lng();
            address.name = place.name;
            address.streetNumber = this.returnValue(place.address_components, "street_number");
            address.route = this.returnValue(place.address_components, "route");
            address.country = this.returnValue(place.address_components, "country");
            address.countryCode = this.returnValue(place.address_components, "country_code");
            address.locality = this.returnValue(place.address_components, "administrative_area_level_3");
            address.postalCode = this.returnValue(place.address_components, "postal_code");
            address.province = this.returnValue(place.address_components, "administrative_area_level_2", true);
            address.region = this.returnValue(place.address_components, "administrative_area_level_1");
            
            this.callback(address)
        }
    }

    returnValue(array: any[], key: string, shortName: boolean = false){
        var object = array.find( x => {
            return x.types.find( y => {
                return y == key
            })
        });
        
        if(object){
            if(!shortName)
                return object.long_name;
            return object.short_name;
        }
        return "";
    }

    changeAddress(data){
        this.provinceList = data;
    }

    emitText(text: string){
        this.localSearchText = text;
    }

}
