import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "eventCard" }
const _hoisted_2 = { class: "infoContainer" }
const _hoisted_3 = { class: "fs_12 purple d-block text-uppercase" }
const _hoisted_4 = { class: "fs_16 spaceCadet d-block text-uppercase fw_700 text-break-word" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      class: "imageContainer",
      style: 'background-image: url(\'' + _ctx.event.coverImage + '\');'
    }, null, 4),
    _createVNode("div", _hoisted_2, [
      _createVNode("span", _hoisted_3, _toDisplayString(_ctx.$filters.date(_ctx.event.dateFrom)) + " - " + _toDisplayString(_ctx.$filters.time(_ctx.event.dateFrom)), 1),
      _createVNode("span", _hoisted_4, _toDisplayString(_ctx.event.name), 1)
    ])
  ]))
}