
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class GoogleAutocompleteGetResults extends Vue {
    
    @Prop({
        default: ""
    }) inputClass: string;

    @Prop({
        default: ""
    }) value: string;

    @Prop({
        default: ""
    }) placeholderText: string;

    @Prop({
        default: function () { return ['it'] }
    }) restrictToCountries: string[];

    localInput: string = "";
    autocomplete: any;

    timeoutHandler;
    @Watch('localInput')
    changedText(){
        if(this.localInput.length < 3)
            return;

        var request = {
            input: this.localInput,
            componentRestrictions: {
                country: []
            },
        }
        if(this.restrictToCountries){
            request.componentRestrictions.country = this.restrictToCountries;
        }
        this.$emit("input", this.localInput);

        clearTimeout(this.timeoutHandler);
        this.timeoutHandler = setTimeout(() => {
            this.autocomplete.getPlacePredictions(request, this.emitResults);
        }, 1000);
    }

    created(){
        this.localInput = this.value;
    }

    mounted(){
        this.autocomplete = new google.maps.places.AutocompleteService();
    }

    emitResults(data, text){
        this.$emit('changeAddress', data)
    }
}
