
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class RemovableImage extends Vue {

    @Prop() url: string;

    bin: boolean = false;
    binTimer: any = null;
    showBin(){
        if(this.bin){
            this.$emit('remove');
            return;
        }
        this.bin = true;
        this.binTimer = setTimeout(() => {
            this.bin = false;
        }, 600);
    }

}

